import { CarComparisonStateType } from '../../widget-filter/CarComparisonStateContext'
import { Car_CarFragment } from './Car.__generated__'
import { DOMAIN, getTranslation } from '../utils/languageUtils'
import { DateTime } from 'luxon'
import { DeepWriteable } from '../utils/generics'
import { SPLink } from '../../components/SPLink'
import { Swiper, SwiperSlide } from 'swiper/react'
import { formatMoney } from '../utils/formatters'
import { genArrBySize, getTransmissionTypeName, litresFromCm3, parseGlobalId } from '../utils'
import { gql } from 'graphql-request'
import { urls } from '../../urls'
import CebiaStar from './CebiaStar'
import React, { useEffect, useState } from 'react'

type Props = {
  car: Car_CarFragment | null | undefined | undefined
  showComparisonButton: boolean
  showOtherProperties?: boolean
  handleClick?: () => void
  onComparisonButtonClick?: () => void
  currLocalSearchUrl?: string
  hideCarComponentContent?: boolean
  comparisonCars?: DeepWriteable<CarComparisonStateType['comparisonCars']>
  lazy?: boolean
}

const styles = {
  gallery: { height: 268 },
}

export const Car = React.forwardRef((props: Props, carRef: any) => {
  const isDevelopment = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

  const [autoplay, setAutoplay] = useState(false)
  const {
    car,
    showComparisonButton = true,
    showOtherProperties = false,
    onComparisonButtonClick,
    currLocalSearchUrl = undefined,
    lazy = true,
    hideCarComponentContent = false,
    comparisonCars = [],
  } = props

  const cebiaReportResultArray = genArrBySize(car?.cebiaReportResult ?? 0)

  const [currentActiveSwiperItem, setCurrentActiveSwiperItem] = useState(1)
  const [previewsSwiper, setPreviewsSwiper] = useState(undefined)

  const loadNextImg = () => {
    setCurrentActiveSwiperItem(prevState => prevState + 1)
  }

  const localStorageComparisonIds = (JSON.parse(
    window.localStorage.getItem('comparison') || '[]'
  ) || []) as string[]

  const [isInComparison, setIsInComparison] = useState(
    localStorageComparisonIds.find(carId => carId === car?.id)
  )

  useEffect(() => {
    const ids = (JSON.parse(window.localStorage.getItem('comparison') || '[]') || []) as string[]
    setIsInComparison(ids.find(carId => carId === car?.id))
  }, [comparisonCars])

  useEffect(() => {
    // @ts-expect-error
    if (previewsSwiper && lazy && previewsSwiper.destroyed !== true) {
      // @ts-expect-error
      previewsSwiper.lazy && previewsSwiper.lazy.loadInSlide(currentActiveSwiperItem)
    }
  }, [previewsSwiper, currentActiveSwiperItem, hideCarComponentContent])

  const urlSearchQuery = new URLSearchParams(currLocalSearchUrl)
  // id means carId
  urlSearchQuery.delete('id')

  if (previewsSwiper) {
    // @ts-expect-error
    previewsSwiper.update()
  }

  const parsedCarId = parseGlobalId(car?.id)
  const carId = parsedCarId && parsedCarId.originalId
  const prettyUrl = car?.prettyUrl ?? ''
  // TODO: should it be calculated somehow?
  // TODO: add doc why we have to calc height
  const HEIGHT_CAR_GALLERY = 240
  const HEIGHT_CAR_DESCRIPTION = 245
  const HEIGHT_ONE_OFFER = 37
  const MIN_HEIGHT_OF_CAR_CARD = 526
  const carCardHeight =
    HEIGHT_CAR_GALLERY +
    HEIGHT_CAR_DESCRIPTION +
    (car?.skofinOffers ?? []).length * HEIGHT_ONE_OFFER

  let isInIFrame = true
  try {
    isInIFrame = window.self !== window.top
  } catch (e) {
    isInIFrame = true
  }

  // @ts-expect-error
  const stopPropagation = e => {
    e.stopPropagation()
  }

  const onSwiperInit = (swiper: any) => {
    // @ts-expect-error
    swiper.slides.forEach(slide => {
      slide.addEventListener('touchmove', stopPropagation)
    })
  }

  return (
    <div
      id={`car-id_${car?.id}`}
      ref={carRef}
      className='saps-car-wrapper'
      style={{
        minHeight: MIN_HEIGHT_OF_CAR_CARD,
        height: carCardHeight,
      }}
    >
      <div className='saps-car'>
        {/*// TODO: add valid url merger*/}
        <SPLink
          market={{
            ...(car?.dealer?.dealerMicrositeId
              ? {
                  Placeholder1: `260-${car?.dealer?.dealerMicrositeId}`,
                }
              : {}),
            ...(car?.id ? { Placeholder2: car?.id.replace(/Car-/g, '') } : {}),
          }}
          href={urls.carDetail({ carId: carId!, prettyUrl, query: urlSearchQuery.toString() })}
          className='saps-car-link'
          onMouseOver={() => {
            setAutoplay(true)
          }}
          onMouseOut={() => setAutoplay(false)}
        >
          {hideCarComponentContent ? (
            <div style={styles.gallery} />
          ) : (
            <div className='saps-car__images-wrapper'>
              {(car?.images ?? []).length > 0 ? (
                <div>
                  {lazy ? (
                    // swiper component is too slow for car-filter component (list of cars)
                    // if we would like to optimise performance of infinite scroll
                    // we should change swiper component to different one because it took
                    // too much time to mount/unmount/reInit <Swiper /> component
                    <Swiper
                      // @ts-expect-error
                      lazy
                      // getSwiper={setPreviewsSwiper} // Get swiper instance callback
                      autoplay={false}
                      // on={{
                      //   slideChange: () => loadNextImg(),
                      // }}
                    >
                      {car?.images?.map((image, index) => (
                        <SwiperSlide key={`image-${index}`}>
                          <div
                            className='saps-car__image swiper-slide swiper-lazy'
                            style={{
                              backgroundImage: isDevelopment
                                ? `url(${DOMAIN ?? ''}${image.normalUrl})`
                                : `url(${image.normalUrl})`,
                              backgroundSize: 'cover', // This ensures the image covers the whole div
                              backgroundRepeat: 'no-repeat',
                              width: '100%',
                              // height: '100%',
                              height: '240px',
                            }}
                            // data-swiper-autoplay='1000'
                          >
                            {/* <div className='swiper-lazy-preloader swiper-lazy-preloader' /> */}
                            {/* <img src={`${DOMAIN}${image.normalUrl}`} /> */}
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  ) : (
                    <Swiper
                      // @ts -expect-error
                      // getSwiper={setPreviewsSwiper} // Get swiper instance callback
                      autoplay={autoplay ? { delay: 1000 } : false}
                      onInit={onSwiperInit}
                      // @ts-expect-error
                      onSlideChange={(a, b) => {
                        // e.stopPropagation()
                        loadNextImg()
                      }}
                    >
                      {car?.images?.map((image, index) => (
                        <SwiperSlide key={`image-${index}`}>
                          <div className='saps-car__image swiper-slide' data-swiper-autoplay='1000'>
                            <img
                              style={{ height: '240px' }}
                              src={
                                isDevelopment
                                  ? `${DOMAIN}/${image.normalUrl}`
                                  : `${image.normalUrl}`
                              }
                              onMouseOver={() => {
                                // if (previewsSwiper) {
                                //   // @ts-expect-error
                                //   previewsSwiper.autoplay.start()
                                // }
                                setAutoplay(true)
                              }}
                              onMouseOut={
                                () => setAutoplay(false)
                                // (previewsSwiper as any)?.autoplay.stop()
                              }
                            />
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  )}
                </div>
              ) : (
                <div className='saps-car-default'>
                  <img
                    className='img-fluid saps-recommended-models__image'
                    src={'/static/defaultCar.png'}
                    alt='default-car-id'
                  />
                </div>
              )}

              {cebiaReportResultArray.length > 3 && (
                <div className='saps-car__cebia'>
                  {cebiaReportResultArray.map((_item, index) => (
                    <CebiaStar
                      color='#ef7d00'
                      width={13}
                      height={13}
                      key={`cebia-star-${index + 1}}`}
                    />
                  ))}
                </div>
              )}
            </div>
          )}

          <div className='saps-car__make'>{car?.model.make.name}</div>
          <div className='saps-car__model'>{car?.model.name}</div>
          <div className='saps-car__model-type'>
            <span className='saps-color-black'> {car?.equipmentLevel?.value}</span>
          </div>
          <div className='saps-car__model-type'>
            {litresFromCm3(car?.engineCapacity ?? 0)} {car?.motorType?.value} | {car?.enginePower}
            kW | {car?.transmission?.id && getTransmissionTypeName(car?.transmission.id)}
            {car?.equipmentItems &&
              !!car.equipmentItems.find(item => item.id === 'equipment_32') &&
              ' | 4x4'}
          </div>
          {showOtherProperties ? (
            <div className='saps-car__other-properties saps-p-small saps-normal'>
              {car?.mileage && (
                <div className='saps-dl'>
                  <div className='saps-dl__title saps-color-grey-300'>
                    {getTranslation('car.props.mileage.label')}
                  </div>
                  <div className='saps-dl__value'>
                    <div className=''>
                      {car.mileage.toLocaleString()} {getTranslation('car.props.mileage.unit')}
                    </div>
                  </div>
                </div>
              )}
              {car?.firstRegistration && (
                <div className='saps-dl'>
                  <div className='saps-dl__title saps-color-grey-300'>
                    {getTranslation('car.props.mileage.firstRegistration')}
                  </div>
                  <div className='saps-dl__value'>
                    {DateTime.fromFormat(car.firstRegistration as any, 'dd-MM-yyyy').year}
                  </div>
                </div>
              )}
              {car?.dealer && (
                <p className='saps-color-secondary saps-text-ellipsis'>{car.dealer.name}</p>
              )}
            </div>
          ) : (
            <div className='saps-pt-50' style={{ backgroundColor: '#fff' }} />
          )}
          <div className='saps-car__prices-and-comparison'>
            <div className='saps-car__prices'>
              {car?.price.value ? (
                <div className='saps-car__price-with-vat'>{formatMoney(car?.price.value)}</div>
              ) : (
                <div />
              )}
              {car?.vatDeductible && !!car.price.exclusiveOfVat ? (
                <div className='saps-car__price'>
                  {formatMoney(car.price.exclusiveOfVat)} {getTranslation('general.noVAT')}
                </div>
              ) : (
                <div className='saps-car__price'>&nbsp;</div>
              )}
            </div>
          </div>
          {car?.demoCarType !== 'ON_REQUEST' &&
            (car?.skofinOffers ?? []).map(offer => (
              <div key={offer.id} className='saps-car__special-offer'>
                {offer.value}
              </div>
            ))}
          {car?.demoCarType === 'ON_REQUEST' && (
            <div className='saps-car__special-offer'>
              {getTranslation('compare.financing.isPreview')}
            </div>
          )}
        </SPLink>
        {showComparisonButton && !isInIFrame && (
          <div
            // TODO: refactor magic constants of bottom coordinates
            // its calculated from height of single skofinOffer div + margin bottom of card
            style={{
              position: 'absolute',
              bottom:
                car?.demoCarType === 'ON_REQUEST' ? 78 : (car?.skofinOffers?.length ?? 0) * 37 + 20,
              right: 0,
              zIndex: 2,
            }}
            className='saps-car__comparison'
            onClick={e => {
              // disable a href click
              e.stopPropagation()
              e.preventDefault()
              onComparisonButtonClick?.()
            }}
          >
            <svg
              className='saps-car__comparison-icon'
              xmlns='http://www.w3.org/2000/svg'
              width='30'
              height='30'
            >
              <path
                className={
                  'saps-car__comparison-icon-path ' +
                  (isInComparison ? '' : 'saps-car__comparison-icon-path--unselected')
                }
                fill='#EF7D00'
                fillRule='evenodd'
                d='M10.635 15.357a3.822 3.822 0 0 1-3.817 3.818A3.823 3.823 0 0 1 3 15.357h1.43a2.39 2.39 0 0 0 2.388 2.388 2.39 2.39 0 0 0 2.387-2.388h1.43zm14.303-4.761h1.43a3.822 3.822 0 0 1-3.819 3.817 3.822 3.822 0 0 1-3.816-3.817h1.43a2.39 2.39 0 0 0 2.386 2.388 2.39 2.39 0 0 0 2.389-2.388zm-3.627 11.15a.715.715 0 1 1 0 1.43H7.861a.715.715 0 0 1 0-1.43h6.01V10.06l-7.026 2.108a.714.714 0 0 1-.41-1.369l15.893-4.768a.715.715 0 0 1 .41 1.369L15.3 9.631v12.114h6.011z'
              />
            </svg>
            <div className='saps-car__comparison-text saps-color-secondary'>
              {getTranslation('button.compare.vehicle')}
            </div>
          </div>
        )}
      </div>
    </div>
  )
})

export const CAR_FRAGMENT = gql`
  fragment Car_car on Car {
    id
    model {
      name: modelName
      make: carMake {
        id
        name
      }
    }
    prettyUrl
    modelType
    images(limit: $carImageLimit) {
      normalUrl
    }
    cebiaReportResult
    mileage
    firstRegistration
    dealer {
      name
      dealerMicrositeId
      isMother
      parentId
    }
    demoCarType
    skofinOffers {
      id
      value(lang: $lang)
    }
    price {
      value
      exclusiveOfVat
    }
    vatDeductible
    enginePower
    engineCapacity
    motorType {
      value(lang: $lang)
    }
    transmission {
      id
    }
    equipmentItems {
      id
    }
    equipmentLevel {
      value
    }
  }
`
