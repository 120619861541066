/**
 * @generated SignedSource<<89ecbe181f7b297a80f497ed57d2d854>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CarsOrderBy = "CREATED_ASC" | "CREATED_DESC" | "FIRST_REGISTRATION_ASC" | "FIRST_REGISTRATION_DESC" | "MILEAGE_ASC" | "MILEAGE_DESC" | "PRICE_ASC" | "PRICE_DESC" | "%future added value";
export type DemoCarTypeInput = "EMPTY" | "FOR_SALE" | "ON_REQUEST" | "%future added value";
export type RegionCountry = "CZ" | "SK" | "%future added value";
export type CarFilterInput = {
  bodyTypes?: ReadonlyArray<string> | null | undefined;
  carsOrderBy?: CarsOrderBy | null | undefined;
  colors?: ReadonlyArray<string> | null | undefined;
  dealers?: ReadonlyArray<string> | null | undefined;
  demoCarTypes?: ReadonlyArray<DemoCarTypeInput | null | undefined> | null | undefined;
  doorsCountRange?: InputIntRange | null | undefined;
  engineCapacity?: InputIntRange | null | undefined;
  enginePower?: InputIntRange | null | undefined;
  equipmentItems?: ReadonlyArray<string> | null | undefined;
  equipmentLevels?: ReadonlyArray<string> | null | undefined;
  firstOwner?: boolean | null | undefined;
  firstRegistration?: InputIntRange | null | undefined;
  fuelTypes?: ReadonlyArray<string> | null | undefined;
  hasServiceBook?: boolean | null | undefined;
  instalmentRange?: InputIntRange | null | undefined;
  isNew?: boolean | null | undefined;
  make?: ReadonlyArray<string | null | undefined> | null | undefined;
  metallicPaint?: boolean | null | undefined;
  mileageRange?: InputIntRange | null | undefined;
  model?: ReadonlyArray<string | null | undefined> | null | undefined;
  oneYearCar?: boolean | null | undefined;
  priceRange?: InputIntRange | null | undefined;
  regionCountry?: RegionCountry | null | undefined;
  regions?: ReadonlyArray<string> | null | undefined;
  seatsCountRange?: InputIntRange | null | undefined;
  showOnHomepage?: boolean | null | undefined;
  skodaPlus?: boolean | null | undefined;
  skofinOffers?: ReadonlyArray<string> | null | undefined;
  transmissionTypes?: ReadonlyArray<string> | null | undefined;
  usedCar?: boolean | null | undefined;
};
export type InputIntRange = {
  max?: number | null | undefined;
  min?: number | null | undefined;
};
export type DealerMap_DealersQuery$variables = {
  filter?: CarFilterInput | null | undefined;
  includeUnused: boolean;
  purchasingCars?: boolean | null | undefined;
};
export type DealerMap_DealersQuery$data = {
  readonly dealers: ReadonlyArray<{
    readonly address: {
      readonly city: string;
      readonly region: string | null | undefined;
      readonly street: string | null | undefined;
      readonly zip: string | null | undefined;
    };
    readonly carsCount: number;
    readonly dealerMicrositeId: string;
    readonly email: string | null | undefined;
    readonly gps: {
      readonly latitude: string | null | undefined;
      readonly longitude: string | null | undefined;
    } | null | undefined;
    readonly id: string;
    readonly isMother: boolean | null | undefined;
    readonly name: string;
  }> | null | undefined;
};
export type DealerMap_DealersQuery = {
  response: DealerMap_DealersQuery$data;
  variables: DealerMap_DealersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeUnused"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "purchasingCars"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      },
      {
        "kind": "Literal",
        "name": "includeMothers",
        "value": true
      },
      {
        "kind": "Variable",
        "name": "includeUnused",
        "variableName": "includeUnused"
      },
      {
        "kind": "Variable",
        "name": "purchasingCars",
        "variableName": "purchasingCars"
      }
    ],
    "concreteType": "Dealer",
    "kind": "LinkedField",
    "name": "dealers",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dealerMicrositeId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isMother",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "GpsCoords",
        "kind": "LinkedField",
        "name": "gps",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "latitude",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "longitude",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Address",
        "kind": "LinkedField",
        "name": "address",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "street",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "city",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "zip",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "region",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "carsCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DealerMap_DealersQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "DealerMap_DealersQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "8d63dcd816aeeaa9210ed708cee36caa",
    "id": null,
    "metadata": {},
    "name": "DealerMap_DealersQuery",
    "operationKind": "query",
    "text": "query DealerMap_DealersQuery(\n  $purchasingCars: Boolean\n  $filter: CarFilterInput\n  $includeUnused: Boolean!\n) {\n  dealers(includeUnused: $includeUnused, purchasingCars: $purchasingCars, filter: $filter, includeMothers: true) {\n    id\n    dealerMicrositeId\n    isMother\n    name\n    email\n    gps {\n      latitude\n      longitude\n    }\n    address {\n      street\n      city\n      zip\n      region\n    }\n    carsCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "74f35fb0bb1b8b52bcdbfb4b01705cfd";

export default node;
