/**
 * @generated SignedSource<<c9381ca8fd6125b49e09fee9ef557602>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CarsOrderBy = "CREATED_ASC" | "CREATED_DESC" | "FIRST_REGISTRATION_ASC" | "FIRST_REGISTRATION_DESC" | "MILEAGE_ASC" | "MILEAGE_DESC" | "PRICE_ASC" | "PRICE_DESC" | "%future added value";
export type DemoCarTypeInput = "EMPTY" | "FOR_SALE" | "ON_REQUEST" | "%future added value";
export type RegionCountry = "CZ" | "SK" | "%future added value";
export type CarFilterInput = {
  bodyTypes?: ReadonlyArray<string> | null | undefined;
  carsOrderBy?: CarsOrderBy | null | undefined;
  colors?: ReadonlyArray<string> | null | undefined;
  dealers?: ReadonlyArray<string> | null | undefined;
  demoCarTypes?: ReadonlyArray<DemoCarTypeInput | null | undefined> | null | undefined;
  doorsCountRange?: InputIntRange | null | undefined;
  engineCapacity?: InputIntRange | null | undefined;
  enginePower?: InputIntRange | null | undefined;
  equipmentItems?: ReadonlyArray<string> | null | undefined;
  equipmentLevels?: ReadonlyArray<string> | null | undefined;
  firstOwner?: boolean | null | undefined;
  firstRegistration?: InputIntRange | null | undefined;
  fuelTypes?: ReadonlyArray<string> | null | undefined;
  hasServiceBook?: boolean | null | undefined;
  instalmentRange?: InputIntRange | null | undefined;
  isNew?: boolean | null | undefined;
  make?: ReadonlyArray<string | null | undefined> | null | undefined;
  metallicPaint?: boolean | null | undefined;
  mileageRange?: InputIntRange | null | undefined;
  model?: ReadonlyArray<string | null | undefined> | null | undefined;
  oneYearCar?: boolean | null | undefined;
  priceRange?: InputIntRange | null | undefined;
  regionCountry?: RegionCountry | null | undefined;
  regions?: ReadonlyArray<string> | null | undefined;
  seatsCountRange?: InputIntRange | null | undefined;
  showOnHomepage?: boolean | null | undefined;
  skodaPlus?: boolean | null | undefined;
  skofinOffers?: ReadonlyArray<string> | null | undefined;
  transmissionTypes?: ReadonlyArray<string> | null | undefined;
  usedCar?: boolean | null | undefined;
};
export type InputIntRange = {
  max?: number | null | undefined;
  min?: number | null | undefined;
};
export type DealerMap_dealerFilterQuery$variables = {
  filter?: CarFilterInput | null | undefined;
};
export type DealerMap_dealerFilterQuery$data = {
  readonly dealers: ReadonlyArray<{
    readonly dealerMicrositeId: string;
    readonly id: string;
    readonly isMother: boolean | null | undefined;
  }> | null | undefined;
};
export type DealerMap_dealerFilterQuery = {
  response: DealerMap_dealerFilterQuery$data;
  variables: DealerMap_dealerFilterQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      },
      {
        "kind": "Literal",
        "name": "includeMothers",
        "value": true
      },
      {
        "kind": "Literal",
        "name": "includeUnused",
        "value": true
      }
    ],
    "concreteType": "Dealer",
    "kind": "LinkedField",
    "name": "dealers",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dealerMicrositeId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isMother",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DealerMap_dealerFilterQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DealerMap_dealerFilterQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3d1e34daf610eb6b4b391e8ee6936ebf",
    "id": null,
    "metadata": {},
    "name": "DealerMap_dealerFilterQuery",
    "operationKind": "query",
    "text": "query DealerMap_dealerFilterQuery(\n  $filter: CarFilterInput\n) {\n  dealers(includeUnused: true, includeMothers: true, filter: $filter) {\n    id\n    dealerMicrositeId\n    isMother\n  }\n}\n"
  }
};
})();

(node as any).hash = "91c0ec5670e7cbf65eb8a436daeeae8a";

export default node;
